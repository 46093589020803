import { useState, useEffect } from 'react';
import { useAuthenticatedApi } from '../utils/api';

// Cache object to store resume analysis data
const cache = {
  data: null,
  timestamp: null,
  loading: false,
  callbacks: new Set(),
};

// Cache duration in milliseconds (5 minutes)
const CACHE_DURATION = 5 * 60 * 1000;

export const useResumeAnalysis = (userId) => {
  const authenticatedApi = useAuthenticatedApi();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userId) return;

    const fetchData = async () => {
      try {
        // If there's valid cached data, use it
        if (
          cache.data &&
          cache.timestamp &&
          Date.now() - cache.timestamp < CACHE_DURATION
        ) {
          setData(cache.data);
          setLoading(false);
          return;
        }

        // If a request is already in progress, wait for it
        if (cache.loading) {
          const callback = (newData) => {
            setData(newData);
            setLoading(false);
          };
          cache.callbacks.add(callback);
          return () => {
            cache.callbacks.delete(callback);
          };
        }

        // Start new request
        cache.loading = true;
        setLoading(true);

        const response = await authenticatedApi.getResumeAnalysis(userId);
        
        if (response.success && response.analysis) {
          cache.data = response.analysis;
          cache.timestamp = Date.now();
          setData(response.analysis);
          
          // Notify all waiting components
          cache.callbacks.forEach(callback => callback(response.analysis));
          cache.callbacks.clear();
        } else {
          throw new Error(response.error || 'Failed to fetch resume analysis');
        }
      } catch (err) {
        setError(err.message);
        cache.callbacks.forEach(callback => callback(null));
        cache.callbacks.clear();
      } finally {
        cache.loading = false;
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, authenticatedApi]);

  return { data, loading, error };
};
