import React from 'react';
import axios from 'axios';
import Layout from '../components/Layout';

const Upgrade = () => {
  const initiatePayment = async (plan) => {
    try {
      let amount = 0;
      if (plan === 'basic') amount = 250;
      else if (plan === 'silver') amount = 290;
      else if (plan === 'gold') amount = 380;

      const response = await axios.post('http://localhost:5000/api/create-order', {
        amount,
        currency: 'INR',
      });

      const { id: order_id, currency } = response.data;

      const options = {
        key: 'rzp_test_zJxnACLhnxNEMd',
        amount: amount * 100,
        currency,
        name: 'Interview Preparation Plans',
        description: 'Upgrade Your Plan',
        order_id,
        handler: function (response) {
          alert('Payment successful!');
          console.log('Payment Details: ', response);
        },
        prefill: {
          name: 'John Doe',
          email: 'johndoe@example.com',
          contact: '9999999999',
        },
        notes: {
          address: 'Corporate Office',
        },
        theme: {
          color: '#3399cc',
        },
      };

      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
    } catch (error) {
      console.error('Error initiating payment: ', error);
    }
  };

  return (
    <Layout>

   
    <div
      className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900 p-4" // Margin for medium screens and above
    >

      <div className="flex flex-col items-center w-full max-w-7xl bg-white dark:bg-gray-900 rounded-lg shadow-lg p-8">
        <h1 className="text-3xl lg:text-6xl font-bold text-gray-900 dark:text-white mb-6 text-center">
          Upgrade Your Plan
        </h1>
        <p className="text-gray-700 dark:text-gray-300 mt-2 text-center max-w-lg">
          Unlock premium features and enhance your interview preparation with our Pro and Gold plans.
          <br />
          Choose the one that suits your needs!
        </p>

        <div className="mt-12 grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full max-w-6xl px-4">
          {/* Basic Plan */}
          <div className="border rounded-xl p-6 md:p-8 shadow-lg bg-white dark:bg-gray-900 transform transition duration-500 hover:scale-105 text-center">
            <h2 className="text-xl md:text-3xl font-semibold text-gray-800 dark:text-white">
              Basic Plan
            </h2>
            <p className="text-gray-500 dark:text-gray-400 mt-2">For beginners</p>
            <p className="text-3xl md:text-4xl font-bold mt-4 text-purple-600">Free</p>
            <ul className="mt-4 space-y-2 text-gray-600 dark:text-gray-400">
              <li>1 Profile</li>
              <li>Access to 50 Questions</li>
              <li>Resume Upload</li>
              <li>Email Support</li>
            </ul>
            <button
              className="w-full bg-purple-600 text-white rounded-lg py-3 mt-8 hover:bg-purple-700 transition"
              onClick={() => initiatePayment('basic')}
            >
              Select Basic
            </button>
          </div>
          {/* Silver Plan */}
          <div className="border rounded-xl p-6 md:p-8 shadow-lg bg-white dark:bg-gray-900 transform transition duration-500 hover:scale-105 text-center">
            <h2 className="text-xl md:text-3xl font-semibold text-gray-800 dark:text-white">
              Silver Plan
            </h2>
            <p className="text-gray-500 dark:text-gray-400 mt-2">For intermediate users</p>
            <p className="text-3xl md:text-4xl font-bold mt-4 text-blue-600">₹499</p>
            <ul className="mt-4 space-y-2 text-gray-600 dark:text-gray-400">
              <li>Up to 3 Profiles</li>
              <li>Access to 200 Questions</li>
              <li>Advanced Resume Analysis</li>
              <li>Priority Support</li>
            </ul>
            <button
              className="w-full bg-blue-600 text-white rounded-lg py-3 mt-8 hover:bg-blue-700 transition"
              onClick={() => initiatePayment('silver')}
            >
              Select Silver
            </button>
          </div>
          {/* Gold Plan */}
          <div className="border rounded-xl p-6 md:p-8 shadow-lg bg-white dark:bg-gray-900 transform transition duration-500 hover:scale-105 text-center">
            <h2 className="text-xl md:text-3xl font-semibold text-gray-800 dark:text-white">
              Gold Plan
            </h2>
            <p className="text-gray-500 dark:text-gray-400 mt-2">For professionals</p>
            <p className="text-3xl md:text-4xl font-bold mt-4 text-green-600">₹999</p>
            <ul className="mt-4 space-y-2 text-gray-600 dark:text-gray-400">
              <li>Up to 5 Profiles</li>
              <li>Unlimited Questions</li>
              <li>AI Feedback</li>
              <li>Mock Interviews</li>
            </ul>
            <button
              className="w-full bg-green-600 text-white rounded-lg py-3 mt-8 hover:bg-green-700 transition"
              onClick={() => initiatePayment('gold')}
            >
              Select Gold
            </button>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default Upgrade;