import { useState, useEffect } from 'react';
import { useAuthenticatedApi } from '../utils/api';

const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes
const questionCache = new Map();
const callbacks = new Map();

export const useQuestions = (userId, questionType) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authenticatedApi = useAuthenticatedApi();

  useEffect(() => {
    let isMounted = true;
    const cacheKey = `${userId}-${questionType}`;

    const fetchQuestions = async () => {
      try {
        // Check cache first
        const cached = questionCache.get(cacheKey);
        if (cached && Date.now() - cached.timestamp < CACHE_EXPIRATION) {
          if (isMounted) {
            setData(cached.data);
            setLoading(false);
          }
          return;
        }

        // If there's already a request in progress, wait for it
        if (callbacks.has(cacheKey)) {
          callbacks.get(cacheKey).push((newData) => {
            if (isMounted) {
              setData(newData);
              setLoading(false);
            }
          });
          return;
        }

        // Start new request
        callbacks.set(cacheKey, []);
        const response = await authenticatedApi.getQuestions(userId, questionType);

        // Update cache
        if (response.success) {
          questionCache.set(cacheKey, {
            data: response,
            timestamp: Date.now(),
          });

          // Update all waiting components
          callbacks.get(cacheKey).forEach(cb => cb(response));
          callbacks.delete(cacheKey);

          if (isMounted) {
            setData(response);
            setLoading(false);
          }
        } else {
          throw new Error(response.error || 'Failed to fetch questions');
        }
      } catch (err) {
        console.error(`Error fetching ${questionType} questions:`, err);
        if (isMounted) {
          setError(err.message);
          setLoading(false);
        }
        callbacks.delete(cacheKey);
      }
    };

    if (userId) {
      fetchQuestions();
    }

    return () => {
      isMounted = false;
    };
  }, [userId, questionType, authenticatedApi]);

  return { data, loading, error };
};
